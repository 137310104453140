import React, { createContext, useState } from 'react';

import { EthereumTransaction } from '@/../transactionTypes';
import createPersistedState from 'use-persisted-state';

const useEthereumTransactionsState = createPersistedState('ethereumTransactions');

export const TransactionContext = createContext({
  setTransactions: undefined,
  setWaitingForTransactionHashes: undefined,
  transactions: [],
  waitingForTransactionHashes: [],
});

interface Props {
  children: React.ReactNode;
}

export const TransactionContextProvider = ({ children }: Props): JSX.Element => {
  const [transactions, setTransactions] = useEthereumTransactionsState<EthereumTransaction[]>([]);
  const [waitingForTransactionHashes, setWaitingForTransactionHashes] = useState([]);

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        setTransactions,
        setWaitingForTransactionHashes,
        waitingForTransactionHashes,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};
