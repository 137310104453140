// Bring public env variables out of process and into js scope
export const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY;
export const ALCHEMY_PROVIDER_PRIORITY = process.env.NEXT_PUBLIC_ALCHEMY_PROVIDER_PRIORITY;
export const ARCX_API_KEY = process.env.NEXT_PUBLIC_ARCX_API_KEY;
export const COZY_API_V2_URL = process.env.NEXT_PUBLIC_COZY_API_V2_URL;
export const COZY_OG_BASE_URL = process.env.NEXT_PUBLIC_COZY_OG_BASE_URL;
export const COZY_SUBGRAPH_URL_PRIMARY = process.env.NEXT_PUBLIC_COZY_SUBGRAPH_URL_PRIMARY;
export const COZY_SUBGRAPH_URL_SECONDARY = process.env.NEXT_PUBLIC_COZY_SUBGRAPH_URL_SECONDARY;
export const DATA_DRIVEN_CURATION_SET_ASSETS_THRESHOLD =
  process.env.NEXT_PUBLIC_DATA_DRIVEN_CURATION_SET_ASSETS_THRESHOLD;
export const GROWTHBOOK_FEATURES_API_KEY = process.env.NEXT_PUBLIC_GROWTHBOOK_FEATURES_API_KEY;
export const INFURA_PROJ_ID = process.env.NEXT_PUBLIC_INFURA_PROJECT_ID;
export const INFURA_PROVIDER_PRIORITY = process.env.NEXT_PUBLIC_INFURA_PROVIDER_PRIORITY;
export const IS_GEOBLOCKED = process.env.NEXT_PUBLIC_IS_GEOBLOCKED == 'true';
export const NODE_ENV = process.env.NODE_ENV;
export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT ?? 'cozy-ui-v2';
export const TRM_API_KEY = process.env.NEXT_PUBLIC_TRM_API_KEY;
export const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV;
export const VERCEL_GIT_COMMIT_SHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;
export const WALLET_CONNECT_PROJECT_ID = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
