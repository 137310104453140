import { BannerHeightContextProvider } from '@/contexts/BannerHeightContext';
import { DefaultChainContextProvider } from '@/contexts/DefaultChainContext';
import { GeoblockContextProvider } from '@/contexts/GeoblockContext';
import { ModalsContextProvider } from './ModalsContext';
import React from 'react';
import { TransactionContextProvider } from '@/contexts/TransactionContext';

interface Props {
  children: React.ReactNode;
}

const ContextProviders: React.FC<Props> = ({ children }) => {
  return (
    <TransactionContextProvider>
      <DefaultChainContextProvider>
        <BannerHeightContextProvider>
          <GeoblockContextProvider>
            <ModalsContextProvider>{children}</ModalsContextProvider>
          </GeoblockContextProvider>
        </BannerHeightContextProvider>
      </DefaultChainContextProvider>
    </TransactionContextProvider>
  );
};

export default ContextProviders;
