import { Box, Flex, useTheme } from '@chakra-ui/react';

import CozyLogo from '@/components/layout/CozyLogo';
import DesktopHeaderNavigation from './header/DesktopHeaderNavigation';
import Link from '@/components/shared/Link';
import MobileAppHeaderNavigation from './header/MobileAppHeaderNavigation';
import React from 'react';
import { Route } from '@/../enums';
import dynamic from 'next/dynamic';
import { routesContainPathname } from '@/helpers/routeHelpers';
import useIsMobileDisplay from '@/hooks/useIsMobileDisplay';
import { useRouter } from 'next/router';

const ActivityBell = dynamic(() => import('../activity/ActivityBell'), { ssr: false });
const CreateButton = dynamic(() => import('./header/CreateButton'), { ssr: false });
const DashboardLink = dynamic(() => import('../dashboard/DashboardLink'), { ssr: false });
const Wallet = dynamic(() => import('../wallet/Wallet'), { ssr: false });

const WHITE_BACKGROUND_HEADER_ROUTES = [
  Route.Root,
  Route.Dashboard,
  Route.Profile,
  Route.CreateProtection,
  Route.CozyForDaos,
  Route.CozySafetyModule,
];

const MOBILE_BOX_SHADOW_ROUTES = [
  Route.CozyForDaos,
  Route.CozySafetyModule,
  Route.CreateProtection,
  Route.Dashboard,
  Route.Profile,
  Route.ProtectionMarket,
];

const Header: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const isMobileDisplay = useIsMobileDisplay();

  if (isMobileDisplay == null) {
    return null;
  }

  const headerHeight = isMobileDisplay ? theme.headerHeight.base : theme.headerHeight.md;
  const logoHeight = 45;

  const backgroundColor = routesContainPathname(router.pathname, WHITE_BACKGROUND_HEADER_ROUTES)
    ? 'white'
    : 'transparent';

  const mobileBoxShadowRoute = routesContainPathname(router.pathname, MOBILE_BOX_SHADOW_ROUTES);

  return (
    <header
      style={{ backgroundColor: isMobileDisplay && mobileBoxShadowRoute ? 'white' : backgroundColor, width: '100%' }}
    >
      <Flex
        backgroundColor={{ base: 'white', md: 'transparent' }}
        boxShadow={{ base: 'lg', md: 'none' }}
        direction="column"
        id="outer-flex"
        margin="auto"
        maxWidth={theme.maxPageWidth}
        minHeight={`${headerHeight}px`}
        mb={{ base: mobileBoxShadowRoute ? 10 : 0, md: 0 }}
      >
        <nav>
          <Flex
            alignItems="center"
            direction="row"
            flex={1}
            id="inner-flex"
            justifyContent="space-between"
            maxW={theme.maxPageWidth}
            minHeight={`${headerHeight}px`}
            pl={{ base: 1, md: 6, xl: 8 }}
            pr={{ base: 3, xl: 8 }}
            pt={{ base: 4, md: 0 }}
          >
            <Box width="300px">
              <Link href={Route.Root}>
                <Box height={logoHeight} ml={{ base: '3', md: '0' }}>
                  <CozyLogo height={logoHeight} full={!isMobileDisplay} />
                </Box>
              </Link>
            </Box>

            {!isMobileDisplay && <DesktopHeaderNavigation />}

            <Flex alignItems="center" justifyContent="flex-end" width="300px">
              <CreateButton />

              <ActivityBell />

              <DashboardLink />

              <Wallet />
            </Flex>
          </Flex>

          {isMobileDisplay && <MobileAppHeaderNavigation />}
        </nav>
      </Flex>
    </header>
  );
};

export default Header;
