import { AdminRoute, Route } from '@/../enums';
import { Market, ProtectionSet, Trigger } from '@/../types';

export const isAdminRoute = (pathname: string) => {
  const routes = Object.values(AdminRoute);

  return routesContainPathname(pathname, routes);
};

export const routesContainPathname = (pathname: string, routes: (Route | AdminRoute)[]) => {
  const route = routes.find((route) => {
    // compare main body of routes
    // ie: /edit-set vs /edit-set/[setId]
    // aftersplit: edit-set === edit-set
    return route.split('/')[1] === pathname.split('/')[1];
  });

  return route != null;
};

export const tabIsActive = (pathname, tab: Route | AdminRoute): boolean => {
  const activeTabMapping = {
    [Route.GetProtection]: [Route.ProtectionMarket, Route.GetProtection, Route.Trigger],
    [Route.ProvideProtection]: [Route.ProtectionSet, Route.ProvideProtection],
    [AdminRoute.Admin]: [AdminRoute.Admin],
    [AdminRoute.YourSets]: [AdminRoute.YourSets, AdminRoute.YourSet, AdminRoute.CreateSet, AdminRoute.EditSet],
    [AdminRoute.YourTriggers]: [AdminRoute.YourTriggers, AdminRoute.CreateTrigger],
  };

  return routesContainPathname(pathname, activeTabMapping[tab]);
};

export const isHomeRoute = (pathname: string): boolean => {
  return pathname === Route.Root;
};

export const marketRoute = (market: Market): string => {
  return marketRouteById(market?.id);
};

export const marketRouteById = (marketId: string): string => {
  return `${Route.ProtectionMarket}/${marketId}`;
};

export const protectionSetRoute = (protectionSet: ProtectionSet): string => {
  return protectionSetRouteById(protectionSet?.id);
};

export const safetyModuleRoute = (protectionSet: ProtectionSet): string =>
  `${Route.ProtectionSet}/${protectionSet?.id}?safety-module=true`;

export const protectionSetRouteById = (protectionSetId: string): string => {
  return `${Route.ProtectionSet}/${protectionSetId}`;
};

export const triggerRoute = (triggerAddress: string): string => {
  return `${Route.Trigger}/${triggerAddress}`;
};

export const editSetRoute = (protectionSet: ProtectionSet, trigger?: Trigger): string => {
  const withTriggerParam = trigger == null ? '' : `?withTrigger=${trigger?.id}`;

  return `${AdminRoute.EditSet}/${protectionSet.id}${withTriggerParam}`;
};

export const editSetRouteById = (protectionSetId: string): string => {
  return `${AdminRoute.EditSet}/${protectionSetId}`;
};

export const yourSetRoute = (protectionSet: ProtectionSet): string => {
  return `${AdminRoute.YourSet}/${protectionSet.id}`;
};

export const dashboardRoute = (tabIndex: number): string => {
  if (tabIndex == null) {
    return Route.Dashboard;
  }

  return `${Route.Dashboard}?tab=${tabIndex}`;
};

export const profileRoute = (address: string, tabIndex?: number): string => {
  const tabQuery = tabIndex == null ? '' : `?tab=${tabIndex}`;

  return `${Route.Profile}/${address}${tabQuery}`;
};
