import { CozyTransactionType, TransactionContextInterface, TransactionStatuses } from '@/../transactionTypes';
import { EthAddress, Market, ProtectionSet } from '@/../types';
import { capitalizeFirstLetter, trackEvent } from '@/utils/analytics';
import { createPublicClient, http } from 'viem';

import { ArcxAnalyticsSdk } from '@arcxmoney/analytics';
import Decimal from 'decimal.js';
import cozyRouterAbi from '@/abis/cozyRouterAbi';
import { findChainById } from '../blockchainExplorerHelpers';
import { updateTransactionsInContext } from '@/helpers/transactionHelpers/transactionNotificationHelpers';

export const MAX_SLIPPAGE = 1.03;
export const GAS_LIMIT_BUFFER = 1.5;

interface CommonRouterTransactionParams {
  onError: (error: any) => void;
  value?: bigint;
  routerAddress: EthAddress;
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
  trackingProps: Record<string, any>;
  walletClient: any;
}

interface CleanUpParams {
  account: string;
  amount?: string;
  chainId: number;
  cleanUpFunction?: (number) => void;
  hash: string;
  market?: Market;
  setIsSubmitted?: (boolean) => void;
  protectionSet?: ProtectionSet;
  trackingProps: Record<string, unknown>;
  transactionContext: TransactionContextInterface;
}
interface ExecuteTransactionParams extends CommonRouterTransactionParams {
  account: string;
  arcx: ArcxAnalyticsSdk;
  args: [string[]] | string[];
  chainId: number;
  cleanUpFunction?: React.Dispatch<React.SetStateAction<string>>;
  functionName:
    | 'aggregate'
    | 'cancel'
    | 'claim'
    | 'completeWithdraw'
    | 'deposit'
    | 'depositWithoutTransfer'
    | 'permitRouter'
    | 'purchase'
    | 'purchaseWithoutTransfer'
    | 'redeem'
    | 'sell'
    | 'unwrapWeth'
    | 'withdraw'
    | 'wrapBaseAssetViaConnectorAndDeposit'
    | 'wrapBaseAssetViaConnectorAndPurchase'
    | 'unwrapWrappedAssetViaConnectorForWithdraw'
    | 'wrapWeth'
    | 'wrapWethForPurchase';
  market?: Market;
  protectionSet: ProtectionSet;
  transactionContext: TransactionContextInterface;
}

interface ExecuteAggregateTransactionParams extends CommonRouterTransactionParams {
  account: string;
  arcx: ArcxAnalyticsSdk;
  encodedCalls: string[];
  chainId: number;
  cleanUpFunction?: React.Dispatch<React.SetStateAction<string>>;
  market?: Market;
  protectionSet?: ProtectionSet;
  transactionContext: TransactionContextInterface;
}

export const getPublicClient = (chainId: number) => {
  if (chainId == null) {
    return null;
  }

  const chain = findChainById(chainId);

  if (chain == null) {
    console.log('No chain found for that chainId.');
    return null;
  }

  const neworkUrl = chain.rpcUrls[0];

  return createPublicClient({
    chain: chain.viemChain,
    transport: http(neworkUrl),
  });
};

export const executeRouterTransaction = async ({
  arcx,
  account,
  chainId,
  cleanUpFunction,
  functionName,
  args,
  market,
  onError,
  protectionSet,
  routerAddress,
  setIsSubmitted,
  trackingProps,
  transactionContext,
  value = BigInt(0),
  walletClient,
}: ExecuteTransactionParams): Promise<string> => {
  const publicClient = getPublicClient(chainId);

  trackEvent(`${capitalizeFirstLetter(trackingProps.type)} Initiated`, { ...trackingProps });

  try {
    const gasEstimate = await publicClient.estimateContractGas({
      abi: cozyRouterAbi,
      account,
      address: routerAddress,
      args: args as any,
      functionName: functionName,
      value: value as never, // Typing issue in Viem expecting never instead of a number
    });

    const bufferedGasLimit = bufferGasLimit(gasEstimate.toString());

    const hash = await walletClient.writeContract({
      abi: cozyRouterAbi,
      account,
      address: routerAddress,
      args: args,
      functionName: functionName,
      gas: BigInt(bufferedGasLimit),
      value: value,
    });

    trackEvent(`${capitalizeFirstLetter(trackingProps.type)} Submitted`, { hash, ...trackingProps });
    arcx?.transaction({ chainId, transactionHash: hash, metadata: trackingProps });

    cleanUpStateAndNotify({
      account,
      chainId,
      cleanUpFunction,
      hash,
      market,
      protectionSet,
      setIsSubmitted,
      trackingProps,
      transactionContext,
    });

    return hash;
  } catch (error) {
    catchError(error, setIsSubmitted, onError, trackingProps);
  }
};

export const executeRouterAggregateTransaction = async ({
  account,
  arcx,
  chainId,
  cleanUpFunction,
  encodedCalls,
  market,
  onError,
  value = BigInt(0),
  protectionSet,
  routerAddress,
  setIsSubmitted,
  trackingProps,
  transactionContext,
  walletClient,
}: ExecuteAggregateTransactionParams): Promise<string> => {
  return await executeRouterTransaction({
    account,
    arcx,
    args: [encodedCalls],
    functionName: 'aggregate',
    chainId,
    cleanUpFunction,
    market,
    onError,
    value,
    protectionSet,
    routerAddress,
    setIsSubmitted,
    trackingProps,
    transactionContext,
    walletClient,
  });
};

export const cleanUpStateAndNotify = async ({
  account,
  chainId,
  cleanUpFunction,
  setIsSubmitted,
  hash,
  market,
  protectionSet,
  trackingProps,
  transactionContext,
}: CleanUpParams): Promise<void> => {
  if (setIsSubmitted) {
    setIsSubmitted(true);
  }

  if (cleanUpFunction) {
    cleanUpFunction('0');
  }

  const sentTransaction = {
    account,
    chainId,
    hash,
    id: hash,
    marketId: market?.id,
    protectionSetId: protectionSet?.id,
    sentAt: Date.now(),
    trackingProps,
    type: trackingProps.type as CozyTransactionType,
    status: TransactionStatuses.Sent,
  };

  updateTransactionsInContext(sentTransaction, transactionContext);
};

export const catchError = (
  error: Error,
  setIsSubmitted: (boolean) => void,
  onError,
  properties: Record<string, unknown> = {},
): void => {
  console.log('Error submitting transaction:', error);

  const name = properties.type ? capitalizeFirstLetter(properties.type as CozyTransactionType) : 'Unknown';

  trackEvent(`${name} Transaction Errored`, { ...properties, error: error });

  if (setIsSubmitted) {
    setIsSubmitted(false);
  }

  if (onError != null) {
    onError(error);
  }
};

// Don't ever return decimal values for contract params
export const convertToRawUnits = (amount: number | string, decimals: number): string => {
  return new Decimal(amount ?? 0).times(Math.pow(10, decimals)).toFixed(0);
};

export const bufferGasLimit = (gasLimit: string): string => {
  return new Decimal(gasLimit).times(GAS_LIMIT_BUFFER).round().toString();
};

export const normalizeVForErc2612Permit = (v: number): number => {
  return v < 27 ? v + 27 : v;
};
