import { Box, BoxProps } from '@chakra-ui/react';

import React from 'react';

interface Props extends BoxProps {
  color: string;
  innerColor?: string;
  dotWidth?: string | number;
}

const Dot: React.FC<Props> = ({ color, innerColor, dotWidth = 9, ...boxProps }: Props) => {
  const halfWidth = Number(dotWidth) / 2;
  const innerWidth = halfWidth - 1.5;

  return (
    <Box {...boxProps}>
      <svg width={dotWidth} height={dotWidth}>
        <circle r={`${halfWidth}px`} cx={`${halfWidth}px`} cy={`${halfWidth}px`} fill={color} />
        <circle r={`${innerWidth}px`} cx={`${halfWidth}px`} cy={`${halfWidth}px`} fill={innerColor ?? color} />
      </svg>
    </Box>
  );
};
export default Dot;
