// GENERIC ADDRESSES
export const ETHEREUM_BURN_ADDRESS = '0x0000000000000000000000000000000000000000';

// ETHEREUM MAINNET ADDRESSES
export const MAIN_WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
export const MAIN_AUSDC_ADDRESS = '0xbcca60bb61934080951369a648fb03df4f96263c';
export const MAIN_USDC_ADDRESS = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';

// OPTIMISM ADDRESSES
export const OPT_WETH_ADDRESS = '0x4200000000000000000000000000000000000006';
export const OPT_AUSDC_ADDRESS = '0x625e7708f30ca75bfd92586e17077590c60eb4cd';
export const OPT_WAUSDC_ADDRESS = '0x41B75372F734ee9b7792ca5576a40E008C91A098';
export const OPT_USDC_ADDRESS = '0x7f5c764cbc14f9669b88837ca1490cca17c31607';
export const OPT_WSTETH_ADDRESS = '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb';

// GOERLI ADDRESSES
export const GO_WETH_ADDRESS = '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6';
