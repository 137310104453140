import { parseAbi } from 'viem';

const cozyRouterAbi = parseAbi([
  'function aggregate(bytes[] memory _calls) external payable returns (bytes[] memory _returnData)',
  'function cancel(address _set, uint16 marketId, uint256 _protection, address _receiver, uint256 _minRefund) external payable returns (uint256 _refund, uint256 _ptokens)',
  'function claim(address _set, uint16 marketId_, uint256 _ptokens, address _receiver) external payable returns (uint256 _protection)',
  'function completeWithdraw(address _set, uint64 _id) external payable',

  'function deployChainlinkTrigger(address truthOracle_, address trackingOracle_, uint256 priceTolerance_, uint256 truthFrequencyTolerance_, uint256 trackingFrequencyTolerance_, (string, string, string, string)) external payable returns (address trigger_)',
  'function deployChainlinkFixedPriceTrigger(int256 price_, uint8 decimals_, address trackingOracle_, uint256 priceTolerance_, uint256 frequencyTolerance_, (string, string, string, string)) external payable returns (address trigger_)',
  'function deployUMATrigger(string query, address rewardToken, uint256 rewardAmount, address refundRecipient, uint256 bondAmount, uint256 proposalDisputeWindow, (string, string, string, string)) external payable returns (address trigger)',
  'function deployCostModelDynamicLevel(uint256 uLow, uint256 uHigh, uint256 costFactorAtZeroUtilization, uint256 costFactorAtFullUtilization, uint256 costFactorInOptimalZone, uint256 optimalZoneRate, bytes32 salt) external payable returns (address model)',
  'function deploySet(address owner, address pauser, address asset, (uint32, uint16, bool), (address, address, address, uint16, uint16, uint16)[], bytes32 salt) external payable returns (address set)',

  'function deposit(address _set, uint256 _assets, address _receiver, uint256 minSharesOut) external payable returns (uint256 _shares)',
  'function depositWithoutTransfer(address _set, uint256 _assets, address _receiver, uint256 minSharesOut) external payable returns (uint256 _shares)',
  'function maxWithdrawalRequest(address set,address owner) external view returns (uint256 withdrawableAssets, uint256 redeemableShares)',
  'function permitRouter(address _token, uint256 _value, uint256 _deadline, uint8 _v, bytes32 _r, bytes32 _s) external payable',
  'function previewPurchase(address set_, uint16 marketId_, uint256 protection_) external view returns (uint256 assetsNeeded, uint256 ptokens, (uint128 totalCost,uint128 cost,uint128 reserveFeeAssets,uint128 backstopFeeAssets,uint128 setOwnerFeeAssets), uint256 safetyModuleFee_)',
  `function previewCancellation(address set, uint16 marketIndex, uint256 protection) external view returns (uint256 refund, uint256 pTokens,(uint128 reserveFeeAssets,uint128 backstopFeeAssets,uint128 supplierFeeAssets))`,
  'function purchase(address _set, uint16 marketIndex, uint256 _protection, address _receiver, uint256 _maxCost) external payable returns (uint256 assetsNeeded_, uint256 _ptokens)',
  'function purchaseWithoutTransfer(address _set, uint16 marketIndex, uint256 _protection, address _receiver) external payable returns (uint256 _totalCost, uint256 _ptokens)',
  'function redeem(address _set, uint256 _shares, address _receiver, uint256 _minAssetsOut) external payable returns (uint256 _assets)',
  'function sell(address _set, uint16 marketId, uint256 _ptokens, address _receiver, uint256 _minRefund) external payable returns (uint256 _refund, uint256 _protection)',
  'function unwrapWeth(address _recipient) external payable',
  'function withdraw(address _set, uint256 _assets, address _receiver, uint256 minSharesOut) external payable returns (uint256 _shares)',
  'function wrapBaseAssetViaConnectorAndDeposit(address connector, address set, uint256 assets, address receiver, uint256 minSharesOut) external payable',
  'function wrapBaseAssetViaConnectorAndPurchase(address connector, address set, uint16 marketId, uint256 protection, address receiver, uint256 maxCost) external payable',
  'function unwrapWrappedAssetViaConnectorForWithdraw(address connector, address receiver) external payable',
  'function wrapWeth(address _recipient, uint256 _amount) external payable',
  'function wrapWethForPurchase(address set, uint16 marketId, uint256 protection, uint256 allowedAmount)',
]);

export default cozyRouterAbi;

//  For the two array paramters in deploySet
// (uint32, uint16, bool) =>
// struct SetConfig {
//   uint32 leverageFactor; // The set's leverage factor.
//   uint16 depositFee; // Fee applied on each deposit and mint.
//   bool rebalanceWeightsOnTrigger; // True if weights should be automatically rebalanced when a market triggers.
// }

// (address, address, address, uint16, uint16, uint16) =>
// struct MarketConfig {
//   ITrigger trigger; // Address of the trigger contract for this market.
//   ICostModel costModel; // Contract defining the cost model for this market.
//   IDripDecayModel dripDecayModel; // The model used for decay rate of PTokens and the rate at which funds are dripped to
//     // suppliers for their yield.
//   uint16 weight; // Weight of this market. Sum of weights across all markets must sum to 100% (1e4, 1 zoc).
//   uint16 purchaseFee; // Fee applied on each purchase.
//   uint16 saleFee; // Penalty applied on ptoken sales.
// }
