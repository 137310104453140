const Button = {
  variants: {
    outline: {
      _hover: {
        bg: 'gray.50',
      },
    },
    ghost: {
      _hover: {
        bg: 'gray.100',
      },
    },
    link: {
      _hover: {
        bg: 'gray.100',
      },
    },
  },
};

export default Button;
