import { AdminRoute, Route } from '@/../enums';

import Button from '@/components/shared/Button';
import Link from '@/components/shared/Link';
import { LinkProps } from '@chakra-ui/react';
import React from 'react';
import { tabIsActive } from '@/helpers/routeHelpers';
import { useRouter } from 'next/router';
import useIsSafetyModule from '@/hooks/useIsSafetyModule';

interface Props extends LinkProps {
  href: Route | AdminRoute;
  label: string;
}

const ACTIVE_BACKGROUND_COLOR = '#F2F6FC';

const HeaderButton: React.FC<Props> = ({ href, label, ...linkProps }) => {
  const router = useRouter();
  const isSafetyModule = useIsSafetyModule();

  const isActive = tabIsActive(router.pathname, href);

  return (
    <Link href={href} {...linkProps}>
      <Button
        backgroundColor={isActive && !isSafetyModule ? ACTIVE_BACKGROUND_COLOR : 'transparent'}
        borderColor="gray.50"
        borderWidth="2px"
        fontWeight={isActive ? 800 : 600}
        height="38px"
        width="full"
        variant={isActive ? 'solid' : 'outline'}
      >
        {label}
      </Button>
    </Link>
  );
};

export default HeaderButton;
