import { trackPage } from '@/utils/analytics';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useOnLoadPageTracking = (): void => {
  const router = useRouter();
  const { pathname } = router;
  useEffect(() => {
    const handleOnLoadPageTrack = () => {
      trackPage(pathname);
    };
    window.addEventListener('DOMContentLoaded', handleOnLoadPageTrack);
  });
};

export default useOnLoadPageTracking;
