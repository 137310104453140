import { AdminRoute, Route } from '../../enums';
import { Market, ProtectionSet } from '@/../types';
import { NODE_ENV, SEGMENT_WRITE_KEY } from '@/constants/envVariables';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { Connector } from 'wagmi';
import { GrowthBook } from '@growthbook/growthbook-react';
import queryString from 'query-string';

/**
 * General purpose module to wrap analytics SDK events,
 * may issue events to multiple analytics providers
 */
const analytics = AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY as string });

const PAGE_NAMES = {
  // App Routes
  [Route.Activity]: 'Activity',
  [Route.Dashboard]: 'Dashboard',
  [Route.GetProtection]: 'Get Protection',
  [Route.Privacy]: 'Privacy',
  [Route.Profile]: 'Profile',
  [Route.ProtectionMarket]: 'Protection Market',
  [Route.ProtectionSet]: 'Protection Set',
  [Route.ProvideProtection]: 'Provide Protection',
  [Route.Root]: 'Homepage',
  [Route.Terms]: 'Terms',
  [Route.Trigger]: 'Trigger',
  // Admin Routes
  [AdminRoute.Admin]: 'Admin Homepage',
  [AdminRoute.CreateSet]: 'Create Set',
  [AdminRoute.CreateTrigger]: 'Create Trigger',
  [AdminRoute.EditSet]: 'Edit Set',
  [AdminRoute.YourSet]: 'Your Set',
  [AdminRoute.YourSets]: 'Your Sets',
  [AdminRoute.YourTriggers]: 'Your Triggers',
};

export const trackPage = (url: string): void => {
  const { address, pageName } = determinePageData(url);

  analytics.page(pageName, { address });
};

export const trackEvent = (name: string, properties: Record<string, unknown> = {}): void => {
  properties = { ...properties, env: NODE_ENV };

  analytics.track(name, properties);
};

const determinePageData = (rawUrl: string): { address?: string; pageName: string } => {
  const parsed = queryString.parseUrl(rawUrl);

  const { url } = parsed;

  const [, routeSegment, address] = url.split('/');

  const displayPageName = PAGE_NAMES[`/${routeSegment}`];

  if (displayPageName != null) {
    return { pageName: displayPageName, address };
  } else {
    return { pageName: url };
  }
};

export const capitalizeFirstLetter = (text: string): string => {
  if (text == null) return '';

  const textArray = text.split(' ');
  const capitizalizedTextArray = textArray.map((item) => item[0].toUpperCase() + item.slice(1));
  return capitizalizedTextArray.join(' ');
};

export const marketAnalyticsProps = (
  market: Market,
  set: ProtectionSet,
  connector: Connector<any, any>,
):
  | {
      marketId: string;
      marketTriggerAddress: string;
      marketName: string;
      walletConnector: string;
    }
  | Record<string, never> => {
  if (market == null) return {};

  return {
    marketId: market.id,
    marketTriggerAddress: market.triggerAddress,
    marketName: market.name,
    walletConnector: connector?.name,
    ...setAnalyticsProps(set, connector),
  };
};

export const setAnalyticsProps = (
  set: ProtectionSet,
  connector: Connector<any, any>,
):
  | {
      leverageFactor: number;
      protectionsetAddress: string;
      protectionSetName: string;
      totalAssets: number;
      underlyingAddress: string;
      underlyingAsset: string;
      underlyingDisplaySymbol: string;
      underlyingPrice: number;
      walletConnector: string;
    }
  | Record<string, never> => {
  if (set == null) return {};

  return {
    leverageFactor: set.leverageFactor,
    protectionsetAddress: set.id,
    protectionSetName: set.name,
    totalAssets: set.totalAssets.div(Math.pow(10, set.underlyingDecimals)).toNumber(),
    underlyingAddress: set.underlyingDepositAssetAddress,
    underlyingAsset: set.underlyingSymbol,
    underlyingDisplaySymbol: set.underlyingDisplaySymbol,
    underlyingPrice: set.underlyingPrice,
    walletConnector: connector?.name,
  };
};

export const configureGrowthbook = () => {
  return new GrowthBook({
    trackingCallback: (experiment, result) => {
      console.log({
        experimentId: experiment.key,
        variationId: result.variationId,
      });
    },
  });
};
