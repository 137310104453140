export enum CozyTransactionType {
  AcceptOwnershipTransfer = 'accept ownership transfer',
  ApplySetUpdate = 'apply set update',
  Approve = 'approve',
  Buy = 'buy',
  CheckTrigger = 'check trigger',
  Claim = 'claim',
  ClaimFees = 'claim fees',
  CompleteWithdraw = 'complete withdraw',
  CreateDripDecayConstantModel = 'create drip decay constant model',
  CreateJumpRateCostModel = 'create jump rate cost model',
  CreateSafetyModule = 'create safety module,',
  CreateSet = 'create set',
  CreateTrigger = 'create trigger',
  Deposit = 'deposit',
  DepositSafetyModule = 'deposit into safety module',
  InitiateSetUpdate = 'initiate set update',
  QuickCreateSet = 'quick create set',
  RequestWithdraw = 'request withdraw',
  RequestWithdrawSafetyModule = 'request withdraw safety module',
  Sell = 'sell',
  TransferOwnership = 'transfer ownership',
  UpdateSetMetadata = 'update set metadata',
}

export interface EthereumTransaction {
  blockNumber?: number;
  chainId: number;
  eventCode?: string;
  from?: string;
  fromAccount?: string;
  hash: string;
  id: string;
  marketId?: string;
  protectionSetId?: string;
  sentAt?: number;
  status: string;
  trackingProps: Record<string, unknown>;
  type?: string;
  uiStatus?: UiStatus;
}

export enum UiStatus {
  Pending = 'pending',
  Displayed = 'displayed',
}

export enum TransactionStatuses {
  Sent = 'sent',
  Pending = 'pending',
  Confirmed = 'confirmed',
  Failed = 'failed',
  NotFound = 'not-found',
  Cancel = 'cancel',
  PendingSimulation = 'pending-simulation',
}

export interface TransactionContextInterface {
  setTransactions: React.Dispatch<React.SetStateAction<EthereumTransaction[]>>;
  setWaitingForTransactionHashes: React.Dispatch<React.SetStateAction<string[]>>;
  transactions: EthereumTransaction[];
  waitingForTransactionHashes: string[];
}

export const SupplyTransactionTypes = [
  CozyTransactionType.CompleteWithdraw,
  CozyTransactionType.Deposit,
  CozyTransactionType.DepositSafetyModule,
  CozyTransactionType.RequestWithdraw,
  CozyTransactionType.RequestWithdrawSafetyModule,
];

export const ProtectTransactionTypes = [CozyTransactionType.Buy, CozyTransactionType.Sell, CozyTransactionType.Claim];
