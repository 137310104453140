import { MAINNET_CHAINALYSIS_CONTRACT_ADDRESSES } from '@/constants/contracts';
import { TRM_API_KEY } from '@/constants/envVariables';
import { TRM_SCREENING_URL } from '@/constants/urls';
import { getPublicClient } from '@/helpers/transactionHelpers/transactionHelpers';
import { poster } from './fetcher';
import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';

const BLOCKED_ADRESSES = [
  '0x2f0cb8d3a398d66141ba4ac05b865a53e9528b4d', // Testing address
];

const CHAINANALYSIS_ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr',
        type: 'address',
      },
    ],
    name: 'isSanctioned',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

export const useDetermineIfAddressIsBlocked = (address: string): { isBlocked: boolean } => {
  const router = useRouter();

  const { data: trmData, error: trmError } = useSWRImmutable(
    () => (address == null ? null : `trm-address-${address}-is-blocked`),
    trmPostFunc(address),
  );
  const trmAddressResult = trmData?.find((dataAddress) => dataAddress.address === address);
  const trmIsSanctioned = trmAddressResult?.isSanctioned;
  if (trmError) {
    console.log('Error checking TRM sanctions list', trmError);
  }

  const { data: chainalysisIsSanctioned, error: chainalysisError } = useSWRImmutable(
    () => (address == null ? null : `chainalysis-address-${address}-is-blocked`),
    chainalysisContractFunc(address),
  );

  if (chainalysisError) {
    console.log('Error checking Chainalysis sanctions list', chainalysisError);
  }

  // If user hasn't connected wallet, don't block
  if (address == null) {
    return { isBlocked: false };
  }

  // Keep BLOCKED_ADDRESS check for testing blocked UX.  Feel free to add additional testing addresses.
  const isBlocked = BLOCKED_ADRESSES.includes(address.toLowerCase()) || trmIsSanctioned || chainalysisIsSanctioned;

  if (isBlocked && router.pathname != '/blocked') {
    router.replace('/blocked');
  }

  return { isBlocked };
};

const trmPostFunc = (address: string) => {
  return poster(TRM_SCREENING_URL, [{ address }], {
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + Buffer.from(`${TRM_API_KEY}:${TRM_API_KEY}`).toString('base64'),
  });
};

const chainalysisContractFunc = (address: string) => {
  const publicClient = getPublicClient(1);

  return () =>
    publicClient.readContract({
      address: MAINNET_CHAINALYSIS_CONTRACT_ADDRESSES,
      abi: CHAINANALYSIS_ABI,
      args: [address],
      functionName: 'isSanctioned',
    });
};
