import React, { createContext, useState } from 'react';

export const BannerHeightContext = createContext({ bannerHeights: {}, setBannerHeights: undefined });

interface Props {
  children: React.ReactNode;
}

export const BannerHeightContextProvider = ({ children }: Props): JSX.Element => {
  const [bannerHeights, setBannerHeights] = useState({});

  return (
    <BannerHeightContext.Provider value={{ bannerHeights, setBannerHeights }}>{children}</BannerHeightContext.Provider>
  );
};
