import colors, {
  ACCENT_COLOR,
  BRAND_COLOR,
  ERROR_COLOR,
  GRAY_100,
  MISSING_ASSET_GRAY,
  SUCCESS_COLOR,
  TEXT_COLOR,
  WARNING_COLOR,
} from './colors';
import { italianPlate, poppins } from '@/utils/fonts';

import Button from './componentThemes/Button';
import Checkbox from './componentThemes/Checkbox';
import NumberInput from './componentThemes/NumberInput';
import { extendTheme } from '@chakra-ui/react';
import fontSizes from './fontSizes';

const theme = extendTheme({
  components: {
    Button,
    Checkbox,
    NumberInput,
  },
  headerHeight: { base: 61, md: 103 },
  settingsPaneWidth: 664,
  maxPageWidth: '1094px', // Max width 70em plus 2em padding on each side
  adminMaxPageWidth: '1334px', // Max width 1270px plus 32px padding on each side
  minMainContentHeight: '900px', // Setting this reduces layout shift
  styles: {
    global: {
      html: {
        fontFamily: 'Sans-Serif',
      },
      body: {
        backgroundColor: '#f8f9fc',
        color: TEXT_COLOR,
      },
    },
  },
  utilityColors: {
    accentColor: ACCENT_COLOR,
    accentSecondaryColor: '#EEEFFF',
    brandColor: BRAND_COLOR,
    brandSecondaryColor: '#F7E4FF',
    errorColor: ERROR_COLOR,
    errorSecondaryColor: '#FFEAEA',
    missingAssetGrayColor: MISSING_ASSET_GRAY,
    warningColor: WARNING_COLOR,
    warningSecondaryColor: '#FFEAD9',
    successColor: SUCCESS_COLOR,
    successSecondaryColor: '#E4fBE4',
  },
  fonts: {
    body: poppins,
    heading: italianPlate,
    mono: poppins,
  },
  fontSizes,
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  breakpoints: {
    xs: '1em',
    sm: '23.5em', // just above 320px
    md: '48em', // 768px
    lg: '59em', // 944px
    xl: '75em', // 1200px
  },
  shadows: {
    highlight: '0px 0px 35px rgba(0, 0, 0, 0.04)',
    sm: '0px 2px 5px rgba(34, 43, 69, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.25)',
    md: '0px 3px 7px rgba(34, 43, 69, 0.06), 0px 5px 35px rgba(34, 43, 69, 0.03)',
    lg: '0px 3px 8px rgba(34, 43, 69, 0.01), 0px 8px 35px rgba(34, 43, 69, 0.05)',
    outline: `0 0 0 2px ${ACCENT_COLOR}`,
    cozyBoxGray: `0px 8px 28px rgba(219, 224, 230, 0.3), 0px 4px 0px ${GRAY_100};`,
  },
  colors,
  sizes: {
    max: 'max-content',
    min: 'min-content',
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '21.5rem',
    sm: '22.5rem',
    md: '418px',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    '8xl': '90rem',
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
  },
});

export default theme;
