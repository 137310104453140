import { Box } from '@chakra-ui/react';
import { Image } from '@/components/shared/Image';
import React from 'react';

interface Props {
  color?: string;
  full?: boolean;
  height: number;
  link?: boolean;
}

const WIDTH_OVER_HEIGHT = 2.3;

const CozyLogo: React.FC<Props> = ({ full = false, link = true, height, color }: Props) => {
  const fullPath = full ? '-full' : '-face';
  const colorPath = color ? `-${color}` : '';
  const src = `/images/logos/cozy-logo${fullPath}${colorPath}.svg`;

  const image = (
    <Image
      alt="Cozy sloth-face logo with Cozy wordmark"
      htmlWidth={full ? height * WIDTH_OVER_HEIGHT : height}
      htmlHeight={height}
      priority
      src={src}
      zIndex="base"
    />
  );

  if (!link) {
    return image;
  }

  return <Box cursor="pointer">{image}</Box>;
};

export default CozyLogo;
