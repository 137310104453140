import { Box, Link as ChakraLink, LinkProps, useTheme } from '@chakra-ui/react';

import NextLink from 'next/link';
import React from 'react';
import { poppins } from '@/utils/fonts';

interface Props extends Omit<LinkProps, 'href'> {
  children: React.ReactNode;
  href?: string;
  isFullWidth?: boolean;
  isDefaultLink?: boolean;
  isDisabled?: boolean;
}

const Link: React.FC<Props> = ({
  children,
  href,
  isDefaultLink,
  isDisabled,
  isExternal,
  isFullWidth,
  onClick,
  ...props
}: Props) => {
  const theme = useTheme();

  const fullWidthProp = isFullWidth ? { width: '100%' } : {};

  if (href == null) {
    return (
      <ChakraLink
        className={poppins.className}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        href={href}
        onClick={isDisabled ? null : onClick}
        _focus={{
          boxShadow: 'none',
          ...props._focus,
        }}
        _hover={{
          textDecoration: isDefaultLink && !isDisabled ? 'underline' : 'none',
          ...props._hover,
        }}
        color={isDisabled ? 'gray.300' : isDefaultLink ? theme.utilityColors.accentColor : 'initial'}
        isExternal={isExternal}
        {...fullWidthProp}
        {...props}
      >
        {children}
      </ChakraLink>
    );
  }

  const color = isDisabled ? 'gray.300' : isDefaultLink ? theme.utilityColors.accentColor : 'initial';

  return (
    <Box
      as="span"
      className={poppins.className}
      color={color}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={isDisabled ? null : onClick}
      {...props}
    >
      <ChakraLink
        as={NextLink}
        href={href}
        _focus={{
          boxShadow: 'none',
          ...props._focus,
        }}
        _hover={{
          textDecoration: isDefaultLink && !isDisabled ? 'underline' : 'none',
          ...props._hover,
        }}
        isExternal={isExternal}
        {...fullWidthProp}
        {...props}
      >
        {children}
      </ChakraLink>
    </Box>
  );
};

export default Link;
