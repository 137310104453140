import {
  CozyTransactionType,
  EthereumTransaction,
  TransactionContextInterface,
  TransactionStatuses,
} from '@/../transactionTypes';
import { Market, ProtectionSet } from '@/../types';
import { capitalizeFirstLetter, marketAnalyticsProps, setAnalyticsProps, trackEvent } from '@/utils/analytics';

import { Connector } from 'wagmi';
import { getCurrentTimestamp } from '../appHelpers';

export const trackAndUpdateTransactionContext = async ({
  blockNumber,
  connector,
  market,
  protectionSet,
  transaction,
  transactionContext,
  transactionStatusFromProvider,
}: {
  blockNumber: number;
  connector: Connector<any, any>;
  market: Market;
  protectionSet: ProtectionSet;
  transaction: EthereumTransaction;
  transactionContext: TransactionContextInterface;
  transactionStatusFromProvider: string;
}): Promise<void> => {
  const { type } = transaction;

  if (transactionStatusFromProvider !== 'success') {
    const failedTransaction = { ...transaction, blockNumber, status: TransactionStatuses.Failed };

    removeTransactionHashFromWaitingOnList(failedTransaction, transactionContext);
    updateTransactionsInContext(failedTransaction, transactionContext);

    trackEvent(
      `${capitalizeFirstLetter(type)} Transaction Failed`,
      transactionTrackingProps(market, protectionSet, failedTransaction, connector),
    );
  } else if (transactionStatusFromProvider === 'success') {
    const confirmedTransaction = { ...transaction, blockNumber, status: TransactionStatuses.Confirmed };

    removeTransactionHashFromWaitingOnList(confirmedTransaction, transactionContext);
    updateTransactionsInContext(confirmedTransaction, transactionContext);

    trackEvent(
      `${capitalizeFirstLetter(type)} Transaction Confirmed`,
      transactionTrackingProps(market, protectionSet, confirmedTransaction, connector),
    );
  }
};

const transactionTrackingProps = (
  market: Market | null,
  set: ProtectionSet | null,
  transaction: EthereumTransaction,
  connector: Connector<any, any>,
) => {
  const setAndMarketProps =
    market != null ? marketAnalyticsProps(market, set, connector) : setAnalyticsProps(set, connector);

  return {
    hash: transaction.hash,
    ...setAndMarketProps,
    ...transaction.trackingProps,
  };
};

export const unconfirmedTransactions = (transactionContext: TransactionContextInterface) => {
  return transactionContext.transactions.filter((t) => transactionContext.waitingForTransactionHashes.includes(t.hash));
};

export const unconfirmedTransactionsByType = (
  protectionSet: ProtectionSet,
  transactionContext: TransactionContextInterface,
  type: CozyTransactionType,
) => {
  return unconfirmedTransactions(transactionContext).filter((t) => {
    return t.type === type && t.protectionSetId === protectionSet.id;
  });
};

export const updateTransactionsInContext = (
  transaction: EthereumTransaction,
  transactionContext: TransactionContextInterface,
): void => {
  const { setTransactions } = transactionContext;

  setTransactions((transactions) => {
    const transactionInContext = transactions.find((tic) => tic.hash === transaction.hash);

    if (transactionInContext) {
      return transactions.map((tic) => {
        if (tic.hash === transaction.hash) {
          return { ...tic, ...transaction };
        } else {
          return tic;
        }
      });
    } else {
      return [...transactions, transaction];
    }
  });
};

export const waitAndUpdateTransaction = async ({
  connector,
  market,
  protectionSet,
  publicClient,
  transaction,
  transactionContext,
}) => {
  const ONE_HOUR = 60 * 60;

  if (transaction == null || publicClient == null) {
    return null;
  }

  try {
    await publicClient.getTransactionReceipt({ hash: transaction.hash });
  } catch {
    // If transaction is more than an hour old but still not found by provider,  mark it as not found and don't block UI on it
    if (transaction.sentAt / 1000 + ONE_HOUR < getCurrentTimestamp()) {
      markTransactionAsNotFound({ connector, market, protectionSet, transaction, transactionContext });
      return null;
    }
  }

  transactionContext.setWaitingForTransactionHashes((hashes) => {
    return [...hashes, transaction.hash];
  });

  const { status: transactionStatusFromProvider, blockNumber } = await publicClient.waitForTransactionReceipt({
    hash: transaction.hash,
  });

  trackAndUpdateTransactionContext({
    blockNumber: Number(blockNumber),
    connector,
    market,
    protectionSet,
    transaction,
    transactionContext,
    transactionStatusFromProvider,
  });
};

export const removeTransactionHashFromWaitingOnList = (transaction, transactionContext) => {
  transactionContext.setWaitingForTransactionHashes((hashes) => {
    return hashes.filter((hash) => hash !== transaction.hash);
  });
};

const markTransactionAsNotFound = ({ connector, market, protectionSet, transaction, transactionContext }) => {
  const notFoundTransaction = { ...transaction, status: TransactionStatuses.NotFound };

  removeTransactionHashFromWaitingOnList(notFoundTransaction, transactionContext);
  updateTransactionsInContext(notFoundTransaction, transactionContext);

  trackEvent(
    `${capitalizeFirstLetter(transaction.type)} Transaction Submitted But Not Found`,
    transactionTrackingProps(market, protectionSet, notFoundTransaction, connector),
  );
};
