import { Box, ButtonProps, Button as ChakraButton, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import ButtonSpinner from './ButtonSpinner';
import { ButtonType } from '@/../enums';
import { SUCCESS_COLOR } from '@/styles/colors';
import { poppins } from '@/utils/fonts';

export interface Props extends ButtonProps {
  appButtonType?: ButtonType;
  children?: ReactNode;
  disabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  spinnerColor?: string;
  onClick?: () => void;
  showTextOnLoading?: boolean;
}

const chooseStylingByType = (type: ButtonType): ButtonProps => {
  switch (type) {
    case ButtonType.Primary: {
      return {
        variant: 'solid',
        colorScheme: 'purple',
        _focus: { boxShadow: '0 0 0 3px rgba(34, 43, 69, 0.6)' },
        _disabled: { opacity: 0.7, cursor: 'not-allowed' },
      };
    }
    case ButtonType.Secondary: {
      return {
        backgroundColor: 'white',
        borderColor: 'gray.100',
        _disabled: {
          _hover: { backgroundColor: 'gray.100', borderColor: 'gray.100' },
          opacity: 0.7,
          cursor: 'not-allowed',
        },
        color: 'gray.700',
        colorScheme: 'gray',
        variant: 'outline',
      };
    }
    case ButtonType.Tertiary: {
      return {
        backgroundColor: 'gray.700',
        borderColor: 'gray.700',
        color: 'white',
        colorScheme: 'blackAlpha',
        _hover: { backgroundColor: 'gray.600', borderColor: 'gray.600' },
      };
    }
    case ButtonType.Success: {
      return {
        backgroundColor: SUCCESS_COLOR,
        color: 'white',
        colorScheme: 'green',
      };
    }
    case ButtonType.Link: {
      return { variant: 'link', colorScheme: 'purple' };
    }
    case ButtonType.Ghost: {
      return {
        variant: 'ghost',
        colorScheme: 'purple',
        _active: { backgroundColor: 'gray.100' },
        _focus: { borderColor: 'transparent' },
      };
    }
    case ButtonType.AdminPrimary: {
      return {
        variant: 'solid',
        colorScheme: 'blackAlpha',
        _focus: { boxShadow: '0 0 0 3px rgba(34, 43, 69, 0.6)' },
        _disabled: { opacity: 0.7, cursor: 'not-allowed' },
      };
    }
    case ButtonType.AdminSecondary: {
      return {
        backgroundColor: 'white',
        borderColor: 'gray.700',
        color: 'gray.700',
        colorScheme: 'gray',
        variant: 'outline',
      };
    }
    case ButtonType.AdminWarning: {
      return {
        colorScheme: 'orange',
        variant: 'solid',
      };
    }
    case ButtonType.DarkMode: {
      return {
        backgroundColor: 'gray.700',
        borderColor: 'white',
        borderWidth: '1px',
        color: 'white',
      };
    }
  }
};

const Button: React.FC<Props> = React.forwardRef(
  (
    {
      children,
      appButtonType = ButtonType.Secondary,
      isFullWidth,
      isLoading,
      showTextOnLoading = true,
      ...props
    }: Props,
    ref,
  ): JSX.Element => {
    const fullWidthProps = isFullWidth ? { width: 'full' } : {};

    return (
      <ChakraButton
        className={poppins.className}
        fontWeight="600"
        borderRadius={100}
        {...fullWidthProps}
        {...chooseStylingByType(appButtonType)}
        {...props}
        {...ref}
      >
        <Flex>
          {isLoading && <ButtonSpinner color={props.spinnerColor} />}

          {isLoading && !showTextOnLoading ? null : <Box ml={isLoading ? 2 : null}>{children}</Box>}
        </Flex>
      </ChakraButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
