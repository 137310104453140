import MobileHeaderNavigation from '@/components/shared/MobileHeaderNavigation';
import React from 'react';
import { Route } from '@/../enums';

const MobileAdminHeaderNavigation: React.FC = () => {
  return (
    <MobileHeaderNavigation
      buttons={[
        { href: Route.GetProtection, label: 'Get Protection' },
        { href: Route.ProvideProtection, label: 'Provide Protection' },
      ]}
    />
  );
};

export default MobileAdminHeaderNavigation;
