import {
  COZY_API_V2_URL,
  COZY_OG_BASE_URL,
  COZY_SUBGRAPH_URL_PRIMARY as ENV_VAR_COZY_SUBGRAPH_URL_PRIMARY,
  COZY_SUBGRAPH_URL_SECONDARY as ENV_VAR_COZY_SUBGRAPH_URL_SECONDARY,
  INFURA_PROJ_ID,
} from './envVariables';

// COZY URLS
export const COZY_API_URL = `${COZY_API_V2_URL}/api`;
export const COZY_BLOG_URL = 'https://mirror.xyz/cozy.eth';
export const COZY_DEVELOPER_DOCS_URL = 'https://docs.cozy.finance/';
export const COZY_DISCORD_URL = 'https://discord.gg/3HWthTMHDd';
export const COZY_GITHUB_URL = 'https://github.com/Cozy-Finance';
export const COZY_JOBS_URL = 'https://www.notion.so/cozyfinance/Work-at-Cozy-0419046da0774b929123b480ad17317e';
export const COZY_OG_URL = COZY_OG_BASE_URL + '/api';
export const COZY_SM_URL = 'https://cozy.finance';
export const COZY_SUBGRAPH_URL_PRIMARY = ENV_VAR_COZY_SUBGRAPH_URL_PRIMARY;
export const COZY_SUBGRAPH_URL_SECONDARY = ENV_VAR_COZY_SUBGRAPH_URL_SECONDARY;
export const COZY_TWITTER_URL = 'https://twitter.com/cozyfinance';
export const COZY_V1_URL = 'https://v1.cozy.finance';

// MISC URLS
export const ENS_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/ensdomains/ens';
export const GROWTHBOOK_API_URL = 'https://cdn.growthbook.io/api/features';
export const TRM_SCREENING_URL = 'https://api.trmlabs.com/public/v1/sanctions/screening';

// MAINNET URLS
export const MAINNNET_EXPLORER_URL = 'https://etherscan.io';
export const MAINNNET_CHAINLINK_PRICE_SUBGRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/cozy-finance/chainlink-prices';
export const MAINNET_INFURA_URL = `https://mainnet.infura.io/v3/${INFURA_PROJ_ID}`;

// OPTIMISM URLS
export const OPT_CHAINLINK_PRICE_SUBGRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/cozy-finance/chainlink-prices-optimism';
export const OPT_EXPLORER_URL = 'https://optimistic.etherscan.io';
export const OPT_INFURA_URL = `https://optimism-mainnet.infura.io/v3/${INFURA_PROJ_ID}`;

// ARBITRUM URLS
export const ARBITRUM_INFURA_URL = `https://arbitrum-mainnet.infura.io/v3/${INFURA_PROJ_ID}`;
