import React, { createContext } from 'react';

import { useDisclosure } from '@chakra-ui/react';

export const ModalsContext = createContext({
  acceptTosIsOpen: false,
  gasEstimateErrorIsOpen: false,
  onCloseAcceptTos: undefined,
  onCloseGasEstimateError: undefined,
  onCloseVisibilitySettings: undefined,
  onOpenAcceptTos: undefined,
  onOpenGasEstimateError: undefined,
  onOpenVisibilitySettings: undefined,
  visiblitySettingsIsOpen: false,
});

interface Props {
  children: React.ReactNode;
}

export const ModalsContextProvider = ({ children }: Props): JSX.Element => {
  const {
    isOpen: gasEstimateErrorIsOpen,
    onClose: onCloseGasEstimateError,
    onOpen: onOpenGasEstimateError,
  } = useDisclosure();

  const { isOpen: acceptTosIsOpen, onClose: onCloseAcceptTos, onOpen: onOpenAcceptTos } = useDisclosure();

  const {
    isOpen: visiblitySettingsIsOpen,
    onClose: onCloseVisibilitySettings,
    onOpen: onOpenVisibilitySettings,
  } = useDisclosure();

  return (
    <ModalsContext.Provider
      value={{
        acceptTosIsOpen,
        gasEstimateErrorIsOpen,
        onCloseAcceptTos,
        onCloseGasEstimateError,
        onCloseVisibilitySettings,
        onOpenAcceptTos,
        onOpenGasEstimateError: setupOnOpen(onOpenGasEstimateError),
        onOpenVisibilitySettings,
        visiblitySettingsIsOpen,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

const setupOnOpen = (onOpen) => (error: Error) => {
  const lowercaseErroMessage = error?.message?.toLowerCase();

  if (lowercaseErroMessage != null && !lowercaseErroMessage.includes('user rejected')) {
    onOpen();
  }
};
