import { BoxProps, Icon, Stack } from '@chakra-ui/react';
import { COZY_DISCORD_URL, COZY_TWITTER_URL } from '@/constants/urls';
import { SiDiscord, SiTwitter } from 'react-icons/si';

import Link from '@/components/shared/Link';
import React from 'react';

const ICONS = [
  {
    icon: SiDiscord,
    url: COZY_DISCORD_URL,
  },
  {
    icon: SiTwitter,
    url: COZY_TWITTER_URL,
  },
];

interface Props extends BoxProps {
  color?: string;
  spacing?: string;
}

const SocialIcons: React.FC<Props> = ({ color, spacing, ...boxPrpos }) => {
  return (
    <Stack alignItems="center" isInline spacing={spacing ?? 4} {...boxPrpos}>
      {ICONS.map(({ icon, url }, index) => (
        <Link key={index} href={url} isExternal>
          <Icon as={icon} boxSize={5} color={color ?? 'gray.200'} />
        </Link>
      ))}
    </Stack>
  );
};

export default SocialIcons;
