import { request } from 'graphql-request';

export const MULTICALL_3_ADDRESS = '0xcA11bde05977b3631167028862bE2a173976CA11';

export const graphQLFetcher = (url: string, query: string) => (): Promise<any> => request(url, query);

export const fetcher = (url: string) => (): Promise<any> => fetch(url).then((r) => r.json());

export const poster =
  (url: string, payload: unknown, headers: HeadersInit = { 'Content-Type': 'application/json' }) =>
  (): Promise<any> =>
    fetch(url, {
      method: 'post',
      headers,
      body: JSON.stringify(payload),
    }).then((r) => r.json());
