import Decimal from 'decimal.js';

export const MAX_UINT_256 = new Decimal(2).pow(256).minus(1).toFixed();
export const MAX_UINT_256_BI = BigInt(MAX_UINT_256);

// ETHEREUM MAINNET CONTRACT ADDRESSES
export const MAINNET_CHAINALYSIS_CONTRACT_ADDRESSES = '0x40C57923924B5c5c5455c48D93317139ADDaC8fb';

// OPTIMISM CONTRACT ADDRESSES
export const OPT_WAUSDC_CONNECTOR_ADDRESS = '0xEABD74ee7399b38d63069039BbD9F1c2fcC8EB88';
