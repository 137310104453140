import { AdminRoute, Route } from '@/../enums';
import { Box, Divider, Flex } from '@chakra-ui/react';

import HeaderButton from '@/components/layout/header/HeaderButton';
import React from 'react';
import { isHomeRoute } from '@/helpers/routeHelpers';
import { useRouter } from 'next/router';

interface Props {
  buttons: { href: Route | AdminRoute; label: string }[];
}

const MobileHeaderNavigation: React.FC<Props> = ({ buttons }) => {
  const router = useRouter();

  if (isHomeRoute(router.pathname)) return null;

  return (
    <Box pb="3">
      <Divider backgroundColor="white" borderColor="gray.50" mb="3" mt="2" />
      <Flex mx="4">
        {buttons.map((button, index) => (
          <HeaderButton flex="1" href={button.href} key={index} label={button.label} mr="2" />
        ))}
      </Flex>
    </Box>
  );
};

export default MobileHeaderNavigation;
