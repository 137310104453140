import { CHAINS } from '@/constants/chains';

const ZAPPER_ACCOUNT_URL = 'https://zapper.fi/account/';

export const blockchainExplorerAddressUrl = (address: string, chainId: number, anchor?: string): string => {
  const chain = findChainById(chainId);

  if (chain?.explorerUrl == null) return null;

  return `${chain.explorerUrl}/address/${address}${conditionallyInsert(anchor)}`;
};

export const blockchainExplorerTransactionUrl = (hash: string, chainId: number, anchor?: string): string => {
  const chain = findChainById(chainId);

  if (chain?.explorerUrl == null) return null;

  return `${chain.explorerUrl}/tx/${hash}${conditionallyInsert(anchor)}`;
};

const conditionallyInsert = (content: string | null): string => {
  return content ? content : '';
};

export const findChainById = (chainId: number) => {
  return CHAINS.find((chain) => chain.chainIdDecimal === chainId);
};

export const zapperAccountUrl = (address: string) => {
  return ZAPPER_ACCOUNT_URL + address;
};
