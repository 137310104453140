import { Spinner as ChakraSpinner, SpinnerProps } from '@chakra-ui/react';

import React from 'react';

type Props = SpinnerProps;

const ButtonSpinner: React.FC<Props> = ({ color = 'white', ...props }: Props) => {
  return <ChakraSpinner color={color} lineHeight="normal" width="1em" height="1em" mb="1" thickness="2px" {...props} />;
};

export default ButtonSpinner;
