import { BoxProps, Divider as ChakraDivider, useTheme } from '@chakra-ui/react';

import React from 'react';

const Divider: React.FC<BoxProps> = (props: BoxProps) => {
  const theme = useTheme();

  return <ChakraDivider borderColor={theme.colors.gray[100]} opacity={1} {...props} />;
};

export default Divider;
