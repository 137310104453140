const fontSizes = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  xl: '18px',
  '2xl': '22px',
  '3xl': '28px',
  '4xl': '36px',
  '5xl': '56px',
  '6xl': '64px',
  '7xl': '72px',
};

export default fontSizes;
