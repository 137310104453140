import '@/styles/styles.css';
import '@rainbow-me/rainbowkit/styles.css';

import { Analytics } from '@vercel/analytics/react';
import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import ContextProviders from '@/contexts/ContextProviders';
import { ErrorBoundary } from '@sentry/nextjs';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import Layout from '@/components/layout/Layout';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import React from 'react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { WagmiConfig } from 'wagmi';
import WalletDisclaimer from '@/components/wallet/WalletDisclaimer';
import { captureException } from '@sentry/nextjs';
import { configureGrowthbook } from '@/utils/analytics';
import { configureWagmi } from '@/helpers/walletHelpers';
import createPersistedState from 'use-persisted-state';
import dynamic from 'next/dynamic';
import fromEntries from 'object.fromentries';
import theme from '@/styles/theme';
import useOnLoadPageTracking from '@/hooks/useOnLoadPageTracking';
import { useRouter } from 'next/router';
import useRouterEventsForProgressBar from '@/hooks/useRouterEventsForProgressBar';
import { useSetupGrowthBook } from '@/hooks/useSetupGrowthBook';

const BlockedPageContent = dynamic(() => import('@/components/blocked/BlockedPageContent'));
const Error = dynamic(() => import('@/components/shared/Error'));
const ArcxAnalyticsProvider = dynamic(() => import('@/components/arcx/ArcxAnalyticsProvider'));

interface CozyAppProps extends AppProps {
  clientIpAddress: string;
  isRegionBlocked: boolean;
}

if (!Object.fromEntries) {
  fromEntries.shim();
}

// Create a GrowthBook instance
export const growthBook = configureGrowthbook();

export const useUserId = createPersistedState('userId');

const { chains, wagmiConfig } = configureWagmi();

const App = ({ clientIpAddress, Component, pageProps }: CozyAppProps): JSX.Element => {
  const { pathname } = useRouter();

  useRouterEventsForProgressBar();
  useOnLoadPageTracking();
  useSetupGrowthBook();

  const isBlockedRoute = pathname === '/blocked';

  if (isBlockedRoute) {
    return (
      <ChakraProvider theme={theme}>
        <WagmiConfig config={wagmiConfig}>
          <BlockedPageContent clientIpAddress={clientIpAddress} />
        </WagmiConfig>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <GrowthBookProvider growthbook={growthBook}>
        <WagmiConfig config={wagmiConfig}>
          <ContextProviders>
            <ArcxAnalyticsProvider>
              <RainbowKitProvider
                appInfo={{ appName: 'Cozy', disclaimer: WalletDisclaimer }}
                chains={chains}
                modalSize="compact"
              >
                <Layout>
                  <ErrorBoundary key={pathname} fallback={<Error />} onError={setupOnError()}>
                    <Component {...pageProps} />
                    <Analytics />
                    <SpeedInsights />
                  </ErrorBoundary>
                </Layout>
              </RainbowKitProvider>
            </ArcxAnalyticsProvider>
          </ContextProviders>
        </WagmiConfig>
      </GrowthBookProvider>
    </ChakraProvider>
  );
};

const setupOnError = () => (error, info) => {
  captureException(error, info);
};

export default App;
