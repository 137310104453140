import React, { createContext, useState } from 'react';

import { DEFAULT_CHAIN_ID } from '@/constants/chains';

export const DefaultChainContext = createContext({ defaultChainId: DEFAULT_CHAIN_ID, setDefaultChainId: undefined });

interface Props {
  children: React.ReactNode;
}

export const DefaultChainContextProvider = ({ children }: Props): JSX.Element => {
  const [defaultChainId, setDefaultChainId] = useState(DEFAULT_CHAIN_ID);

  return (
    <DefaultChainContext.Provider value={{ defaultChainId, setDefaultChainId }}>
      {children}
    </DefaultChainContext.Provider>
  );
};
