import { growthBook, useUserId } from '@/pages/_app';

import { GROWTHBOOK_API_URL } from '@/constants/urls';
import { GROWTHBOOK_FEATURES_API_KEY } from '@/constants/envVariables';
import { captureException } from '@sentry/nextjs';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';

export const useSetupGrowthBook = () => {
  const [userId, setUserId] = useUserId();

  useEffect(() => {
    // Load feature definitions from API
    fetch(`${GROWTHBOOK_API_URL}/${GROWTHBOOK_FEATURES_API_KEY}`)
      .then((res) => res.json())
      .then((json) => {
        growthBook.setFeatures(json.features);
      })
      .catch((error) => {
        console.log('Error loading growthbook features', error);
        captureException(error);
      });

    const id = nanoid();
    if (!userId) setUserId(id);

    // Targeting attributes
    growthBook.setAttributes({
      id: userId,
    });
  }, [setUserId, userId]);
};
