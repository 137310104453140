export enum AdminRoute {
  Admin = '/admin',
  CreateSafetyModule = '/create-safety-module',
  CreateSet = '/create-set',
  CreateTrigger = '/create-trigger',
  EditSet = '/edit-set',
  YourSet = '/your-set',
  YourSets = '/your-sets',
  YourTriggers = '/your-triggers',
  QuickCreate = '/quick-create',
}

export enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
  Link,
  Ghost,
  AdminPrimary,
  AdminSecondary,
  AdminWarning,
  Success,
  DarkMode,
}

export enum CostModelType {
  Custom = 'custom',
  DynamicLevel = 'dynamicLevel',
  JumpRate = 'jumpRate',
}

export enum CreateChainlinkTriggerCompareTo {
  Oracle = 'oracle',
  FixedValue = 'fixedValue',
}

export enum CurationFilter {
  Default = 'default',
  All = 'all',
}

export enum GrowthBookFeature {
  AnnoucementBanner = 'announcement-banner',
  DisableAllTransactions = 'disable-all-transactions',
  DisableDepositsAssetList = 'disable-deposits-asset-list',
  DefaultCostModelParams = 'default-cost-model-params',
  ProtectionSetsLists = 'protection-sets-lists',
  TriggerLists = 'trigger-lists',
  UmaQueryTemplates = 'uma-query-templates',
  V2WhitelistedAccounts = 'v2-whitelisted-accounts',
}

export enum Route {
  Activity = '/activity',
  CozyForDaos = '/cozy-for-daos',
  CozySafetyModule = '/cozy-safety-module',
  CreateProtection = '/create-protection',
  Dashboard = '/dashboard',
  GetProtection = '/get-protection',
  Privacy = '/privacy',
  Profile = '/profile',
  ProtectionMarket = '/protection-market',
  ProtectionSet = '/protection-set',
  ProvideProtection = '/provide-protection',
  Root = '/',
  Terms = '/terms',
  PreviousTerms = '/previous-terms',
  Trigger = '/trigger',
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending',
}

export enum SortValue {
  ActiveCoverage = 'activeCoverage',
  Apy = 'apy',
  AvailableProtection = 'availableProtection',
  Balance = 'balance',
  Cost = 'cost',
  CostFullUtilization = 'costFullUtilization',
  CostKinkUtilization = 'costKinkUtilization',
  CostZeroUtilization = 'costZeroUtilization',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  CumulativeFees = 'cumulativeFees',
  CumulativeVolume = 'cumulativeVolume',
  FundedFrom = 'fundedFrom',
  Id = 'id',
  LeverageFactor = 'leverageFactor',
  MarketSize = 'marketSize',
  Name = 'name',
  NumMarkets = 'numMarkets',
  OptimalStart = 'uLow',
  OptimalEnd = 'uHigh',
  Relevance = 'relevance',
  State = 'state',
  SuppliedFunds = 'suppliedFunds',
  TriggerType = 'triggerType',
  UnderlyingSymbol = 'underlyingSymbol',
  Usage = 'usage',
  Utilization = 'utilization',
  Weight = 'weight',
}

export enum TriggerCategory {
  Staking = 'staking',
  Lending = 'lending',
  Bridge = 'bridge',
  DEX = 'dex',
  CDP = 'cdp',
  Uncategorized = 'uncategorized',
}

export enum TriggerState {
  Active = 'active',
  Frozen = 'frozen',
  Triggered = 'triggered',
}

export enum ProtectionSetState {
  Active = 'active',
  Paused = 'paused',
  Frozen = 'frozen',
}

export enum TriggerType {
  ChainlinkOracle = 'ChainlinkOracle',
  UmaOracle = 'UmaOracle',
  Custom = 'Custom',
}

export enum UmaQueryTemplateEnum {
  BoilerplateHack = 'boilerplateHack',
  BoilerplateHackQuickCreate = 'boilerplateHackQuickCreate',
}

export enum UserPersona {
  Seeker = 'seeker',
  Supplier = 'supplier',
}

export enum StateType {
  TriggerState,
  ProtectionSetState,
}

export enum WagmiConnectionStatus {
  Connecting = 'connecting',
  Reconnecting = 'reconnecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
}
