import { COZY_SM_URL } from '@/constants/urls';
import { DisclaimerComponent } from '@rainbow-me/rainbowkit';
import React from 'react';
import { Route } from '@/../enums';

const WalletDisclaimer: DisclaimerComponent = ({ Text, Link }) => {
  return (
    <Text>
      <span>By connecting your wallet, you represent that you have read, understood and agreed to the&nbsp;</span>
      <Link href={COZY_SM_URL + Route.Terms}>Terms of Service</Link>
      <span>
        <span>&nbsp;including the&nbsp;</span>
        <Link href={COZY_SM_URL + Route.Terms + '#Risks-of-Use'}>Risks of Use</Link>
        <span>
          , and that you understand this website includes user-generated content that has not been approved by Cozy.
        </span>
      </span>
    </Text>
  );
};

export default WalletDisclaimer;
