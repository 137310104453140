import { INFURA_PROJ_ID, WALLET_CONNECT_PROJECT_ID } from '@/constants/envVariables';
import { configureChains, createConfig } from 'wagmi';

import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { infuraProvider } from 'wagmi/providers/infura';
import { optimism } from 'wagmi/chains';

export const abbreviateEthAddress = (address: string): string => {
  return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
};

export const connectorIsWalletConnect = (connector): boolean => {
  return connector?.name === 'WalletConnect';
};

export const configureWagmi = (): { chains; wagmiConfig } => {
  const { chains, publicClient } = configureChains([optimism], [infuraProvider({ apiKey: INFURA_PROJ_ID })]);

  const { connectors } = getDefaultWallets({
    appName: 'Cozy',
    chains,
    projectId: WALLET_CONNECT_PROJECT_ID,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return { chains, wagmiConfig };
};
