import { ACCENT_COLOR } from '../colors';

const NumberInput = {
  defaultProps: {
    focusBorderColor: ACCENT_COLOR,
    errorBorderColor: 'gray.100',
  },
};

export default NumberInput;
