import { HStack } from '@chakra-ui/react';
import HeaderLink from './HeaderLink';
import React from 'react';
import { Route } from '@/../enums';
import { tabIsActive } from '@/helpers/routeHelpers';
import { useRouter } from 'next/router';
import useIsSafetyModule from '@/hooks/useIsSafetyModule';

interface NavOption {
  href: string;
  isActive: boolean;
  text: string;
}

interface Props {
  navOptions?: (router) => NavOption[];
}

const NAV_OPTIONS = (router) => [
  { href: Route.GetProtection, isActive: tabIsActive(router.pathname, Route.GetProtection), text: 'Get Protection' },
  {
    href: Route.ProvideProtection,
    isActive: tabIsActive(router.pathname, Route.ProvideProtection),
    text: 'Provide Protection',
  },
];

const DesktopHeaderNavigation: React.FC<Props> = ({ navOptions }) => {
  const isSafetyModule = useIsSafetyModule();
  const router = useRouter();

  const options = navOptions != null ? navOptions(router) : NAV_OPTIONS(router);

  return (
    <HStack
      backgroundColor="white"
      borderRadius="full"
      boxShadow="box-shadow: 0px 5px 35px 0px rgba(34, 43, 69, 0.03); box-shadow: 0px 3px 7px 0px rgba(34, 43, 69, 0.06);"
      my={1}
      p="2"
      spacing="2px"
    >
      {options.map((option, i) => (
        <HeaderLink href={option.href} isActive={!isSafetyModule && option.isActive} key={i} text={option.text} />
      ))}
    </HStack>
  );
};

export default DesktopHeaderNavigation;
