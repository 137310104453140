import NProgress from 'nprogress'; //nprogress module
import { trackPage } from '@/utils/analytics';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useRouterEventsForProgressBar = (): void => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      trackPage(url);

      if (!url.includes('muteProgressBar=true')) {
        NProgress.start();
      }
    };
    const handleRouteChangeComplete = () => NProgress.done();
    const handleRouteChangeError = () => NProgress.done();

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  });
};

export default useRouterEventsForProgressBar;
