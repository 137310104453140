import {
  ARBITRUM_INFURA_URL,
  COZY_SUBGRAPH_URL_PRIMARY,
  COZY_SUBGRAPH_URL_SECONDARY,
  MAINNET_INFURA_URL,
  MAINNNET_CHAINLINK_PRICE_SUBGRAPH_URL,
  MAINNNET_EXPLORER_URL,
  OPT_CHAINLINK_PRICE_SUBGRAPH_URL,
  OPT_EXPLORER_URL,
  OPT_INFURA_URL,
} from './urls';
import { MAIN_WETH_ADDRESS, OPT_WETH_ADDRESS } from './tokens';
import { arbitrum, mainnet, optimism } from 'viem/chains';

import { Chain } from '../../types';

export const ETHEREUM_CHAIN_ID = 1;
export const GOERLI_CHAIND_ID = 5;
export const OPTIMISM_CHAIN_ID = 10;
export const ARBITRUM_CHAIN_ID = 42161;
export const DEFAULT_CHAIN_ID = OPTIMISM_CHAIN_ID;

export const CHAINS: Chain[] = [
  // NEED MAINNET FOR CHAIN-ANALYSIS SANCTIONS LIST CALL
  {
    chainId: '0x1',
    chainIdDecimal: ETHEREUM_CHAIN_ID,
    chainlinkName: 'ethereum',
    chainlinkPriceSubgraphUrl: MAINNNET_CHAINLINK_PRICE_SUBGRAPH_URL,
    cozySubgraphUrlPrimary: null,
    explorerUrl: MAINNNET_EXPLORER_URL,
    isDev: true,
    isPreview: true,
    isProd: true,
    isTestNet: false,
    logo: 'ethereum.png',
    name: 'Mainnet',
    rpcUrls: [MAINNET_INFURA_URL],
    wethAddress: MAIN_WETH_ADDRESS,
    viemChain: mainnet,
  },
  {
    chainId: '0xa',
    chainIdDecimal: OPTIMISM_CHAIN_ID,
    chainlinkName: 'optimism',
    chainlinkPriceSubgraphUrl: OPT_CHAINLINK_PRICE_SUBGRAPH_URL,
    cozySubgraphUrlPrimary: COZY_SUBGRAPH_URL_PRIMARY,
    cozySubgraphUrlSecondary: COZY_SUBGRAPH_URL_SECONDARY,
    explorerUrl: OPT_EXPLORER_URL,
    isDev: true,
    isPreview: true,
    isProd: true,
    isTestNet: false,
    logo: 'optimism.png',
    name: 'Optimism',
    rpcUrls: [OPT_INFURA_URL],
    wethAddress: OPT_WETH_ADDRESS,
    viemChain: optimism,
  },
  {
    chainId: '0xA4B1',
    chainIdDecimal: ARBITRUM_CHAIN_ID,
    chainlinkName: 'arbitrum',
    chainlinkPriceSubgraphUrl: null,
    cozySubgraphUrlPrimary: null,
    cozySubgraphUrlSecondary: null,
    explorerUrl: null,
    isDev: true,
    isPreview: true,
    isProd: true,
    isTestNet: false,
    logo: 'arbitrum.png',
    name: 'Arbitrum',
    rpcUrls: [ARBITRUM_INFURA_URL],
    wethAddress: null,
    viemChain: arbitrum,
  },
];
