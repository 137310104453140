import { Box, Flex, FlexProps, useTheme } from '@chakra-ui/react';

import React from 'react';

interface Props extends FlexProps {
  children: React.ReactNode;
  maxInnerBoxWidth?: string;
}

const CenterBox: React.FC<Props> = ({ children, maxInnerBoxWidth, ...flexProps }) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center" flexDir="column" px={{ base: 4, md: 6, xl: 8 }} {...flexProps}>
      <Box maxW={maxInnerBoxWidth ?? theme.maxPageWidth} width="100%">
        {children}
      </Box>
    </Flex>
  );
};

export default CenterBox;
