import * as React from 'react';

import { HTMLChakraProps, PropsOf, SystemProps, UseImageProps, chakra, useImage, useTheme } from '@chakra-ui/react';

import Dot from './Dot';
import NI from 'next/image';
import { omit } from 'lodash';

interface NextImageOptions {
  /**
   * The native HTML `width` attribute to the passed to the `img`
   */
  htmlWidth?: string | number;
  /**
   * The native HTML `height` attribute to the passed to the `img`
   */
  htmlHeight?: string | number;
}

interface NextImageProps extends PropsOf<'img'>, NextImageOptions {}

const imageLoader = ({ src, width }) => {
  if (src.includes('pbs.twimg.com')) {
    // Twitter doesn't seem to allow this?
    return `${src}`;
  }
  return `${src}?w=${width}&q=${100}`;
};

const NextImage = React.forwardRef(function NextImage(props: NextImageProps, ref: React.Ref<any>) {
  const { htmlWidth, htmlHeight, ...rest } = props;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <NI width={htmlWidth} height={htmlHeight} loader={imageLoader} {...ref} {...rest} />;
});

interface ImageOptions extends NextImageOptions {
  /**
   * Fallback image `src` to show if image is loading or image fails.
   *
   */
  fallbackSrc?: string;
  /**
   * Fallback element to show if image is loading or image fails.
   * @type React.ReactElement
   */
  fallback?: React.ReactElement;
  /**
   * Defines loading strategy
   */
  loading?: 'eager' | 'lazy';
  /**
   * How the image to fit within its bounds.
   * It maps to css `object-fit` property.
   * @type SystemProps["objectFit"]
   */
  fit?: SystemProps['objectFit'];
  /**
   * How to align the image within its bounds.
   * It maps to css `object-position` property.
   * @type SystemProps["objectPosition"]
   */
  align?: SystemProps['objectPosition'];
  /**
   * If `true`, opt out of the `fallbackSrc` logic and use as `img`
   */
  ignoreFallback?: boolean;
}

export interface ImageProps extends UseImageProps, Omit<HTMLChakraProps<'img'>, keyof UseImageProps>, ImageOptions {
  priority?: boolean;
}

export type Ref = HTMLImageElement;

/**
 * React component that renders an image with support
 * for fallbacks
 *
 * @see Docs https://chakra-ui.com/components/image
 */
export const Image = React.forwardRef<Ref, ImageProps>(function Image(props, ref) {
  const theme = useTheme();

  const { fallback, src, align, fit, loading, ignoreFallback, priority, crossOrigin, ...rest } = props;

  /**
   * Defer to native `img` tag if `loading` prop is passed
   * @see https://github.com/chakra-ui/chakra-ui/issues/1027
   */
  const shouldIgnore = (loading != null && loading != 'eager') || ignoreFallback;

  const status = useImage({
    ...props,
    ignoreFallback: shouldIgnore,
  });

  const shared = {
    ref,
    objectFit: fit,
    objectPosition: align,
    ...(shouldIgnore ? rest : omit(rest, ['onError', 'onLoad'])),
  };

  if (status === 'failed') {
    /**
     * If user passed a custom fallback component,
     * let's render it here.
     */
    if (fallback) return fallback;

    return <Dot color={theme.utilityColors.missingAssetGrayColor} dotWidth={rest.htmlWidth} />;
  }

  return (
    <chakra.img
      as={NextImage}
      className="chakra-image"
      crossOrigin={crossOrigin}
      loading={loading}
      priority={priority}
      src={src}
      {...shared}
    />
  );
});

export interface ImgProps extends HTMLChakraProps<'img'>, NextImageOptions {}

Image.displayName = 'Image';
