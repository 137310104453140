import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react';

import React from 'react';
import { italianPlate } from '@/utils/fonts';

const lineHeightAndLetterSpacingProps = (props) => {
  const weightAndHeight = {
    fontWeight: '800',
    lineHeight: '110%',
  };

  // Heading 1 or Heading 2
  if (props.fontSize === 'xl' || props.fontSize === '2xl') {
    return weightAndHeight;
  } else {
    // Hero
    return {
      fontWeight: '800',
      lineHeight: '120%',
      letterSpacing: '-0.01em',
    };
  }
};

const Heading: React.FC<HeadingProps> = ({ children, ...props }: HeadingProps) => {
  return (
    <ChakraHeading
      className={italianPlate.className}
      fontFamily={italianPlate}
      {...lineHeightAndLetterSpacingProps(props)}
      {...props}
    >
      {children}
    </ChakraHeading>
  );
};
export default Heading;
