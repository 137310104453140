import React, { createContext, useEffect, useState } from 'react';

import { COZY_API_URL } from '@/constants/urls';
import { GrowthBookFeature } from '@/../enums';
import { IS_GEOBLOCKED } from '@/constants/envVariables';
import { useAccount } from 'wagmi';
import { useDetermineIfAddressIsBlocked } from '@/utils/addressBlocking';
import { useDisclosure } from '@chakra-ui/react';
import { useGrowthBook } from '@growthbook/growthbook-react';

export const GeoblockContext = createContext({
  countryCode: undefined,
  isBlocked: undefined,
  modalIsOpen: undefined,
  modalOnClose: undefined,
  modalOnOpen: undefined,
});

interface Props {
  children: React.ReactNode;
}

const GROWTHBOOK_WHITELISTS = ['v2-testers', 'cozy-team', 'ecp'];

const GEO_IP_API_URL = `${COZY_API_URL}/geoip`;

export const GeoblockContextProvider = ({ children }: Props): JSX.Element => {
  const [countryCode, setCountryCode] = useState<any | undefined>(undefined);
  const [isBlocked, setIsBlocked] = useState<any | undefined>(undefined);
  const { isOpen: modalIsOpen, onOpen: modalOnOpen, onClose: modalOnClose } = useDisclosure();
  const { address: account } = useAccount();
  useDetermineIfAddressIsBlocked(account);

  const growthBook = useGrowthBook();

  const whitelists = growthBook.getFeatureValue(
    GrowthBookFeature.V2WhitelistedAccounts,
    GROWTHBOOK_WHITELISTS.reduce((acc, curr) => {
      return { ...acc, [curr]: [] };
    }, {}),
  );

  const ecpTeamWhitelist: string[] = whitelists['cozy-team'].concat(whitelists['ecp']);

  const isEcpTeamWhitelisted = listContainsAddress(account, ecpTeamWhitelist);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(GEO_IP_API_URL);
        const { 'x-vercel-ip-country': countryCode } = await response.json();

        const noCountryFound = countryCode == null;
        const countryIsTeamEcpBlocked = noCountryFound || ECP_TEAM_BLOCKED_COUNTRIES.includes(countryCode);
        const countryIsBlocked = noCountryFound || BLOCKED_COUNTRIES.includes(countryCode);

        if (isMounted) {
          setCountryCode(countryCode);

          if (!IS_GEOBLOCKED) {
            // If app isn't in geoblock mode, no one is blocked
            setIsBlocked(false);
          } else if (isEcpTeamWhitelisted) {
            setIsBlocked(countryIsTeamEcpBlocked);
          } else {
            // All cases should be handled above, but default to blocked to be safe
            setIsBlocked(countryIsBlocked);
          }
        }
      } catch (error) {
        console.log('Error fecthing geo IP data', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  });

  return (
    <GeoblockContext.Provider
      value={{
        countryCode,
        isBlocked,
        modalIsOpen,
        modalOnOpen,
        modalOnClose,
      }}
    >
      {children}
    </GeoblockContext.Provider>
  );
};

const listContainsAddress = (address: string, list: string[]) => {
  const lowercaseList = list.map((listAddress) => listAddress.toLowerCase());

  return lowercaseList.includes(address?.toLowerCase());
};

export const ECP_TEAM_BLOCKED_COUNTRIES = [
  'AL', // Albania
  'BA', // Bosnia
  'BY', // Belarus
  'BA', // Bosnia
  'BG', // Bulgaria
  'BI', // Burundi
  'CF', // Central African Republic
  'CI', // Côte d'Ivoire
  'CD', // Congo, Democratic Republic of the
  'CU', // Cuba
  'HR', // Croatia
  'IQ', // Iraq
  'IR', // Iran
  'LB', // Lebanon
  'LY', // Libya
  'KP', // North Korea
  'ME', // Montenegro
  'MK', // North Macedonia
  'MM', // Myanmar
  'RO', // Romania
  'RU', // Russia
  'RS', // Serbia
  'SI', // Slovenia
  'SD', // Sudan
  'SO', // Somalia
  'SS', // South Sudan
  'SY', // Syrian Arab Republic
  'UA', // Ukraine
  // 'US', // US isn't blocked if you're ECP
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];

const UK_COUNTRIES = [
  'GB', // Great Britain, Scotland, Wales
  'IE', // Ireland
];

export const BLOCKED_COUNTRIES = [
  ...ECP_TEAM_BLOCKED_COUNTRIES,
  ...UK_COUNTRIES,
  'US', // Untied State of America
];
