import Button from '@/components/shared/Button';
import Link from '@/components/shared/Link';
import React from 'react';
import { italianPlate } from '@/utils/fonts';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
  href?: string;
  text: string;
}

const ACTIVE_BACKGROUND_COLOR = '#F2F6FC';

const HeaderLink: React.FC<Props> = ({ href, isActive, isDisabled, text }: Props) => {
  return (
    <Link href={href} isDisabled={isDisabled}>
      <Button
        backgroundColor={isActive ? ACTIVE_BACKGROUND_COLOR : null}
        borderColor="transparent"
        className={italianPlate.className}
        isDisabled={isDisabled}
        fontFamily={italianPlate}
        fontWeight={isActive ? '800' : '600'}
      >
        {text}
      </Button>
    </Link>
  );
};

export default HeaderLink;
