export const ACCENT_COLOR = '#5057FA';
export const BRAND_COLOR = '#E077FF';
export const WARNING_COLOR = '#F1A060';
export const WARNING_COLOR_SECONDARY = '#FFF3D6';
export const ERROR_COLOR = '#F72B6F';
export const TEXT_COLOR = '#222B45';
export const GRAY_100 = '#EDEFF2';
export const GRAY_500 = '#708599';
export const MISSING_ASSET_GRAY = '#EEEFFF';
export const COST_BLUE = '#8597F2';
export const SUCCESS_COLOR = '#00D02F';

const colors = {
  blue: {
    100: '#EFF2FF',
    500: '#506EFA',
    600: '#506EFA',
  },
  gray: {
    50: '#FAFBFC',
    100: GRAY_100,
    200: '#DBE0E6',
    300: '#B8C2CC',
    500: GRAY_500,
    700: TEXT_COLOR,
    800: '#222B45',
  },
  green: {
    100: '#DEFCE2',
  },
  purple: {
    500: ACCENT_COLOR, // Button
    600: '#3C40B2', // Hover
    700: '#000A96', // Pressed
  },
  red: {
    100: '#FFECEF',
    200: '#FFDDDB',
    500: ERROR_COLOR,
  },
  orange: {
    100: WARNING_COLOR_SECONDARY,
    500: WARNING_COLOR,
  },
  blackAlpha: {
    500: TEXT_COLOR,
  },
  lightGray: {
    50: '#FAFBFC', // gray.50
    500: '#DBE0E6', // gray.200
  },
  utilizationBarGray: {
    50: '#FAFBFC', // gray.50
    500: '#B8C2CC', // gray.300
  },
};

export default colors;
