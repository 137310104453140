import { Box, Flex, Show } from '@chakra-ui/react';

import { COZY_SM_URL } from '@/constants/urls';
import CenterBox from '../shared/CenterBox';
import Divider from '../shared/Divider';
import Heading from '@/components/shared/Heading';
import Link from 'next/link';
import React from 'react';
import { Route } from '../../../enums';
import SocialIcons from '../home/SocialIcons';

interface Props {
  backgroundColor?: string;
}

const GlobalNavBar: React.FC<Props> = ({ backgroundColor = 'transparent' }) => {
  const links = generateLinks();

  return (
    <nav style={{ width: '100%' }}>
      <CenterBox backgroundColor={backgroundColor}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            {links.map(({ color, href, fontWeight, label }, index) => {
              const isFirstTwoLinks = index < 2;

              return (
                <Box
                  borderY="none"
                  display={{ base: isFirstTwoLinks ? 'block' : 'none', md: 'block' }}
                  key={index}
                  px="4"
                  py="3.5"
                >
                  <Link href={href} passHref>
                    <Heading
                      color={color ?? 'gray.300'}
                      fontSize="sm"
                      fontWeight={fontWeight}
                      textTransform="uppercase"
                    >
                      {label}
                    </Heading>
                  </Link>
                </Box>
              );
            })}
          </Flex>
          <Show above="md">
            <SocialIcons />
          </Show>
        </Flex>
      </CenterBox>

      <Divider />
    </nav>
  );
};

const generateLinks = () => {
  return [
    { href: COZY_SM_URL, label: 'Safety Modules' },
    { color: '#58627A', href: Route.GetProtection, fontWeight: 800, label: 'Protection Markets' },
  ];
};

export default GlobalNavBar;
